import React, { Component } from 'react';
import './AndieBox.sass';
import SmallText from '../smallText/Smalltext';
import PropTypes from 'prop-types';
import Utils from '../../../base/Utils';
import andieFull from '../../../resources/images/andie/andieFull.png';
import andie from '../../../resources/images/andie/andie.png';
import image1 from '../../../resources/images/andie/image1.png';
import image2 from '../../../resources/images/andie/image2.jpg';
import i18n from '../../../i18n';
import Config from '../../../base/config/Config';
import Cookies from 'js-cookie';

class AndieBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            andieArray: [
                {
                    type: 'andie',
                    text: i18n.t('Welcome! Let\'s craft captivating social media content.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'user',
                    text: i18n.t('ANDIE, create enhanced content, please.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'filters',
                    filters: [
                        [
                            {
                                title: i18n.t('Tone of voice'),
                                value: i18n.t('Friendly')
                            },
                            {
                                title: i18n.t('Language'),
                                value: Cookies.get('currentLanguage').toUpperCase()
                            },
                            {
                                title: i18n.t('Content'),
                                value: i18n.t('Carousel')
                            },
                            {
                                title: i18n.t('Framework'),
                                value: i18n.t('AIDA')
                            }
                        ],
                        [
                            {
                                title: i18n.t('Length'),
                                value: i18n.t('Short')
                            },
                            {
                                title: i18n.t('Business Area'),
                                value: i18n.t('Marketing Agency')
                            }
                        ],
                        [
                            {
                                title: i18n.t('Topics'),
                                value: i18n.t('AI, content generation, virtual assistant')
                            }
                        ],
                        [
                            {
                                title: i18n.t('Keywords'),
                                value: i18n.t('Artificial intelligence, gain time, assistant, post creation')
                            }
                        ],
                        [
                            {
                                title: i18n.t('Target Audience'),
                                value: i18n.t('Marketing DIRECTOR')
                            }
                        ]
                    ],
                    sameBubble: true,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('Got it! Let\'s make that happen.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('Meet ANDIE, your social media companion. Elevate your content game with AI-powered assistance. Create, optimize, and engage like never before.'),
                    button: i18n.t('Add hashtags'),
                    sameBubble: true,
                    arrow: true
                },
                {
                    type: 'user',
                    text: i18n.t('ANDIE, add hashtags to the content, please.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('Happy to assist with that request.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('Meet ANDIE, your social media companion. Elevate your content game with AI-powered assistance. Create, optimize, and engage like never before.'),
                    hashtags: i18n.t('#AI #contentgeneration #virtualassistant'),
                    button: i18n.t('Suggest images'),
                    sameBubble: true,
                    arrow: false
                },
                {
                    type: 'user',
                    text: i18n.t('Andie, please suggest images for the content.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('No problem, I\'ve got your back.'),
                    sameBubble: false,
                    arrow: false
                },
                {
                    type: 'andie',
                    text: i18n.t('Meet ANDIE, your social media companion. Elevate your content game with AI-powered assistance. Create, optimize, and engage like never before.'),
                    hashtags: i18n.t('#AI #contentgeneration #virtualassistant'),
                    images: [image1, image2],
                    sameBubble: true,
                    arrow: false
                }
            ],
            showNumber: 1
        };
    }

    handleButton(key) {
        var { showNumber, andieArray } = this.state;
        if (key === showNumber) {
            this.setState({ showNumber: showNumber + 1 }, () => {
                if (!Utils.isset(andieArray[Utils.clone(this.state.showNumber) - 1], {}).button && showNumber < andieArray.length - 1) {
                    setTimeout(() => {
                        this.handleButton(this.state.showNumber);
                    }, 800);
                }
                var objDiv = document.getElementById('AndiescrollArea');
                objDiv.scrollTop = objDiv.scrollHeight;
            });
        }
    }

    render() {
        var andieArray = this.state.andieArray;
        var showNumber = this.state.showNumber;

        var language = (Cookies.get('currentLanguage') || Config.mainLang).toLowerCase();
        var language = language.split('-')[0];
        var arrowUp = require(`../../../resources/images/andie/try-andie-arrow-up-${language}.svg`);
        var arrowDown = require(`../../../resources/images/andie/try-andie-arrow-${language}.svg`);

        return (
            <div className='AndieOutsideBox'>
                <div className='AndieBox'>
                    <div className='AndieBoxImageArea'>
                        <img className='AndieBoxImage' src={andieFull} />
                    </div>
                    <div style={showNumber === andieArray.length ? { borderRadius: '0px' } : {}} id='AndiescrollArea' className='AndieBackgroundBox'>
                        <div className='AndieBackgroundContentBox'>
                            {andieArray.map((item, key) => {
                                if (parseInt(key) < showNumber)
                                    return <div className={'AndieResultBoxOutsideArea' + (item.type !== 'andie' ? ' User' : '') + (item.sameBubble ? ' SameBubble' : '')}>
                                        <div key={key} className={'AndieResultBoxArea' + (item.type !== 'andie' ? ' User' : '') + (item.sameBubble ? ' SameBubble' : '')}>
                                            {item.type === 'andie' && <div>
                                                <img style={{ visibility: !item.sameBubble ? 'visible' : 'hidden', marginRight: '8px' }} src={andie} />
                                            </div>}
                                            <div>
                                                {item.filters ?
                                                    <div className='AndieTopContent'>
                                                        {item.filters.map((filterItem) => {
                                                            return <div className='AndieTopFilterArea'>
                                                                {filterItem.map((filter) => {
                                                                    return <div className='AndieTopFilter'>
                                                                        <SmallText size='Medium' text={filter.title + ':'} />
                                                                        <SmallText size='Medium' type='Eight' text={filter.value} />
                                                                    </div>;
                                                                })}
                                                            </div>;
                                                        })}
                                                    </div>
                                                    :
                                                    <div className={'AndieResultBottomBoxOutsideArea' + (item.type !== 'andie' ? ' User' : '') + (item.sameBubble ? ' SameBubble' : '')} >
                                                        <div className={'AndieResultBottomBoxArea' + (item.type !== 'andie' ? ' User' : '') + (item.sameBubble ? ' SameBubble' : '')}>
                                                            {!item.sameBubble && <SmallText size='Medium' font='RobotoBold' type='Ninth' text={item.type === 'andie' ? 'ANDIE' : 'Brooklyn Simmons (Me)'} />}
                                                            <SmallText size='Big' font='Roboto' text={item.text} />
                                                            {Utils.isset(item.hashtags, '').length > 0 && <SmallText size='Big' type='Gradient' text={item.hashtags} />}
                                                            {Utils.isset(item.images, []).length > 0 && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {item.images.map((image) => {
                                                                    return <img style={{ marginRight: '4px', marginBottom: '4px', width: '48px', height: '48px' }} src={image} />;
                                                                })}
                                                            </div>}
                                                            {Utils.isset(item.button, '').length > 0 && <div className='AndieResultBottomBoxButtonArea'>
                                                                <div onClick={() => this.handleButton(parseInt(key) + 1)} className='AndieResultBottomBoxOutsideButton'>
                                                                    <div className='AndieResultBottomBoxButton'>
                                                                        <img className='andieSmallImage' src={andie} />
                                                                        <SmallText size='Big' text={item.button} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>}
                                                {(showNumber === parseInt(key) + 1 && item.arrow) && <div className='TryImageAreaUp'>
                                                    <img src={arrowUp} />
                                                </div>}
                                            </div>
                                            {item.type !== 'andie' && <div>
                                                <img style={{ visibility: !item.sameBubble ? 'visible' : 'hidden', marginLeft: '8px' }} src={andie} />
                                            </div>}
                                        </div>
                                    </div>;
                            })}
                        </div>
                    </div>
                    {showNumber < 2 ?
                        <div className='AndieBackgroundBoxButtonOutsideArea' >
                            <div className='TryImageArea'>
                                <img src={arrowDown} />
                            </div>
                            <div className='AndieBackgroundBoxButtonArea'>
                                <div onClick={() => this.handleButton(showNumber)} className='AndieBackgroundBoxButton'>
                                    <img src={andie} />
                                    <SmallText text={i18n.t('Compose Content')} />
                                </div>
                            </div>
                        </div> :
                        showNumber === andieArray.length ?
                            <div style={{ background: '#F5F5F5' }} className='AndieBackgroundBoxButtonArea'>
                                <a className='AnimatedButton' href={`${Config.appHost}register?utm_source=website&utm_medium=andie&utm_campaign=cta-3`}>
                                    <div className='AndieBackgroundBoxBackgroundOutsideButton'>
                                        <div className='AndieBackgroundBoxButton'>
                                            <img src={andie} />
                                            <SmallText text={i18n.t('Try ANDIE Free')} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            : null
                    }
                </div>
            </div >
        );
    }
}

AndieBox.propTypes = {
    andieArray: PropTypes.array
};

export default AndieBox;
