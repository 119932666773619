import React, { Component, lazy } from 'react';
import './Andie.sass';
import i18n from '../../../../../i18n';
import Config from '../../../../../base/config/Config';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import DataArray from '../../../../../base/config/DataArray';
import check from './../../../../../resources/icons/checkBlue.svg';
import AndieBox from '../../../../component/andieBox/AndieBox';
import andieIcon from '../../../../../resources/icons/category/andie.png';
import andieIconArea from '../../../../../resources/icons/category/Group 24934.svg';
import NewArea from '../../../../component/newArea/NewArea';
const Button = lazy(() => import('./../../../../component/button/Button'));
const Title = lazy(() => import('../../../../component/title/Title'));
const Message = lazy(() => import('./../../../../component/message/Message'));
const SmallText = lazy(() => import('./../../../../component/smallText/Smalltext'));

class Andie extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var dataArray = DataArray.andieDetails;
        var currentLanguage = Cookies.get('currentLanguage');

        var title = i18n.t('Looking for Engaging Social Media Posts generated by Artificial Intelligence? |ANDIE, your new social media assistant powered by Swonkie.|');

        var startTitle = title.indexOf('|') + 1;
        var endTitle = title.indexOf('|', startTitle);
        var text_to_get = title.substring(startTitle, endTitle);

        title = title.replace(`|${text_to_get}|`, `<span class = "Blue" >${text_to_get}</span>`);

        return (
            <div id='andie' className="AndieArea">
                <div className="AndieContentArea">
                    <div className='AndieLeftArea'>
                        <div className="AndieAreaTop">
                            <div className="AndieMessageArea">
                                <div style={{ boxShadow: '0px 9px 13px #00000029', maxWidth: '84px', maxHeight: '84px', padding: '10px', borderRadius: '8px' }}>
                                    <img style={{ marginBottom: '0px' }} alt="CategoryIconArea" src={andieIcon} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <SmallText type="Fourth" font="SofiaProBold" size="Big" text={i18n.t('AI assistant').toUpperCase()} />
                                    <NewArea type='Andie' />
                                </div>
                                <Title size="Medium" type="Secundary" html={title} text="" />
                                <Message size="Big" type="Secundary" text={i18n.t('Create social media posts for Facebook, Instagram, LinkedIn, Twitter, and other networks in just a few minutes.\nAnd the best part? ANDIE, your new Social Media Assistant, will tailor them to your brand\'s voice, industry, target audience, and much more.')} />
                            </div>
                            <div className="AndieButtonArea">
                                <a href={`${Config.appHost}register?utm_source=website&utm_medium=andie&utm_campaign=cta-2`}><Button size="Small" type="Primary" text={i18n.t('Try ANDIE Free')} /> </a>
                                <Link to={`/${currentLanguage}/contact`}> <Button size="Small" type="PrimaryPricingButton" text={i18n.t('Request a Demo')} /> </Link>
                            </div>
                            <div className="AndietextArea">
                                {dataArray.map((category, key) => {
                                    return (
                                        <div className="AndieText" key={key}>
                                            <img src={check} alt="check" />
                                            <SmallText size="Big" type="Secundary" font="OpenSans" text={category.text} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='AndieRightArea'>
                        <AndieBox />
                    </div>
                </div>
            </div >
        );
    }
}

export default Andie;
